<template>
  <b-modal
    id="modal-lg"
    v-model="conditionUser"
    hide-footer
    hide-header
    centered
    no-close-on-backdrop
    size="lg"
    @hide="onHide"
  >
    <form @submit.prevent="onSubmit">
      <validation-provider #default="{ errors }" name="Region" rules="">
        <b-form-group label="REGION" label-for="REGION">
          <v-select
            id="REGION"
            v-model="dataRegion"
            :options="optionsRegion"
            :reduce="(value) => value.id"
            :clearable="false"
            label="name"
          />
          <small class="text-danger">{{ errors[0] }}</small>
        </b-form-group>
      </validation-provider>
      <div class="d-flex justify-content-end mt-2">
        <b-button
          type="button"
          class="mr-2"
          variant="outline-danger"
          @click="onHide"
        >
          {{ $t("Cancel") }}
        </b-button>
        <b-button variant="primary" type="submit" :disabled="loading">
          <span v-if="!loading">Change</span>
          <span v-else>
            <SpinnerLoading />
          </span>
        </b-button>
      </div>
    </form>
  </b-modal>
</template>

<script>
import { BButton, BModal, BFormGroup, BAvatar } from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";

import SpinnerLoading from "@/components/SpinnerLoading";
import NotificationMixin from "@/mixins/NotificationMixin";

import vSelect from "vue-select";

import axiosR from "@/services/admin/regions";
export default {
  components: {
    BButton,
    BModal,
    BFormGroup,
    BAvatar,

    ValidationProvider,
    ValidationObserver,

    SpinnerLoading,
    vSelect,
  },
  data() {
    return {
      conditionUser: true,
      dataRegion: this.RegionById.region,
      optionsRegion: [],
      loading: false,
    };
  },
  props: {
    RegionById: {
      type: Object,
      required: true,
    },
  },
  mixins: [NotificationMixin],
  mounted() {
    this.getRegion();
  },
  methods: {
    onHide() {
      this.$emit("closeModalFacility", false);
    },
    getRegion() {
      axiosR.regionList(10).then(({ registro: {data} }) => {
        this.optionsRegion = data;
      });
    },
    onSubmit() {
      this.loading = true;
      const data = {
        regions_id: this.dataRegion
      };
      axiosR.regionFacilityChange(this.RegionById.facilityId, data).then((res) => {
        this.loading = false;
        const { type, message } =
          NotificationMixin.methods.notificationCheck(res);
        this.$emit("NotificationCheck", { type, message });
        this.onHide();
      });
    },
  },
};
</script>

<style lang="scss">
</style>