<template>
  <div>
    <div v-if="regionData" class="container-chasis">
      <b-img
        src="https://img.freepik.com/fotos-premium/mapa-mundo-naranja-sobre-fondo-pared-blanca-ilustracion-3d-banner-horizontal_118047-12121.jpg?w=2000"
        alt="Responsive image"
        class="image-region"
      />
      <div class="centered">
        <h1 class="font-weight-bold">{{ regionData.name }}</h1>
      </div>
    </div>
    <b-row v-if="usersRegion">
      <b-col cols="12">
        <div class="d-flex align-items-center justify-content-end">
          <GoBack class="mr-2" />
          <b-button
            variant="primary"
            @click="isAddNewRegionTeamSidebarActive = true"
            :disabled="loading"
          >
            <span v-if="!loading" class="text-nowrap">{{ $t("Add") }} Facility</span>
            <span v-else>
              <SpinnerLoading />
            </span>
          </b-button>
        </div>
      </b-col>
      <b-col v-for="facility in usersRegion" lg="3" sm="12" :key="facility.id">
        <b-card class="card-profile">
          <div class="profile-image-wrapper">
            <div class="profile-image p-0">
              <b-avatar size="114" variant="light" :src="facility.avatar" />
            </div>
          </div>
          <h3>{{ facility.name }}</h3>
          <h6 class="text-muted">{{ facility.email }}</h6>
          <b-badge class="profile-badge" variant="light-primary">
            {{facility.phone}}
          </b-badge>
          <hr class="mb-2" />

          <!-- follower projects rank -->
          <div class="d-flex justify-content-between align-items-center mb-1">
            <div>
              <h6 class="text-muted font-weight-bolder">Followers</h6>
              <h3 class="mb-0">10.3k</h3>
            </div>
            <div>
              <h6 class="text-muted font-weight-bolder">Projects</h6>
              <h3 class="mb-0">156</h3>
            </div>
            <div>
              <h6 class="text-muted font-weight-bolder">Rank</h6>
              <h3 class="mb-0">23</h3>
            </div>
          </div>
          <!--/ follower projects rank -->
          <b-card-footer class="d-flex justify-content-between">
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="primary"
              class="btn-icon"
              v-b-tooltip.hover.top="'Change'"
              @click="changeUserRegion(facility.id)"
            >
              <feather-icon icon="RefreshCcwIcon" />
            </b-button>
            <b-button
              v-ripple.400="'rgba(40, 199, 111, 0.15)'"
              variant="danger"
              class="btn-icon"
              v-b-tooltip.hover.top="'Delete'"
              @click="DeleteUser(facility.id)"
            >
              <feather-icon icon="XIcon" />
            </b-button>
          </b-card-footer>
        </b-card>
      </b-col>
    </b-row>
    <b-row v-else>
      <SpinnerLoading />
    </b-row>
    <UserChangeRegion
      v-if="conditionChangeRegion"
      :RegionById="RegionById"
      @closeModalFacility="conditionChangeRegion = false"
      @NotificationCheck="NotificationCheck"
    />
    <UserAddRegion
      v-if="isAddNewRegionTeamSidebarActive"
      :is-add-new-region-team-sidebar-active.sync="
        isAddNewRegionTeamSidebarActive
      "
      :regionData="regionData && regionData.id"
      @addUserRegion="createRegionUser"
    />
    <ToastNotification ref="toast" />
  </div>
</template>

<script>
import {
  BImg,
  BRow,
  BCol,
  BCard,
  BCardFooter,
  BAvatar,
  BButton,
  BBadge,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

import axiosR from "@/services/admin/regions";

import UserChangeRegion from "./UserChangeRegion.vue";
import UserAddRegion from "./UserAddRegion";

import ToastNotification from "@/components/ToastNotification";
import SpinnerLoading from "@/components/SpinnerLoading";
import NotificationMixin from "@/mixins/NotificationMixin";
import GoBack from "@/components/ButtonBack.vue";

export default {
  components: {
    BImg,
    BRow,
    BCol,
    BCard,
    BCardFooter,
    BAvatar,
    BBadge,
    BButton,
    SpinnerLoading,
    ToastNotification,
    GoBack,
    UserChangeRegion,
    UserAddRegion,
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  mixins: [NotificationMixin],
  data() {
    return {
      regionData: null,
      usersRegion: null,
      RegionById: null,
      conditionChangeRegion: false,
      isAddNewRegionTeamSidebarActive: false,
      loading: false
    };
  },
  mounted() {
    this.getRegion();
  },
  methods: {
    getRegion() {
      axiosR
        .regionDetail(this.$route.params.id)
        .then(({ registro: { region, users } }) => {
          this.regionData = region;
          this.usersRegion = region.facilities;
        });
    },
    DeleteUser(id) {
      this.$swal({
        title: "Are you sure?",
        text: "You won't be able to revert this",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          axiosR.regionFacilityDelete(id).then((res) => {
            const { type, message } =
              NotificationMixin.methods.notificationCheck(res);
            this.$refs.toast[type](message);
            this.getRegion();
          });
        }
      });
    },
    changeUserRegion(facilityId) {
      const data = {
        facilityId,
        region: this.regionData.id,
      };
      this.RegionById = data;
      this.conditionChangeRegion = true;
    },
    createRegionUser({ type, message }) {
      this.NotificationCheck({ type, message });
      this.isAddNewRegionTeamSidebarActive = false;
      
    },
    NotificationCheck({ type, message }) {
      this.$refs.toast[type](message);
      this.getRegion();
    },
  },
};
</script>
<style lang="scss" scoped>
.container-chasis {
  width: 100%;
  position: relative;
  text-align: center;
  color: white;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: black;
}
.centered {
  position: absolute;
  top: 90%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.image-region {
  width: 100%;
  height: 400px;
  margin: 0 auto 2rem;
  object-fit: cover;
  object-position: bottom;
}
.card-profile {
  margin-top: 3rem;
}
</style>